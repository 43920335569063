<template>
  <div class="container">
    <div class="header">确认发票</div>
    <div class="desc">
      <span
        style="
          display: inline-block;
          width: 2px;
          height: 14px;
          background: #1890ff;
          margin-right: 5px;
        "
      ></span>
      请选择任务开票金额
    </div>
    <div>
      <s-table
        ref="qyryTable"
        style="border-top: 1px solid #f0f0f0; margin-top: 16px"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columnsQyry"
        :data="dataQyry"
        :rowKey="(record) => record.payId"
      >
        <span
          slot="action"
          slot-scope="text, record"
          v-if="raskDetailInfo.status !== '50'"
        >
          <a v-if="record.signStatus === '20'" @click="cancelSign(record)"
            >取消用工</a
          >
        </span>
        <span slot="signStatus" slot-scope="text">
          <div class="table-point" v-if="text === '20'">
            <span style="background: #1890ff"></span> 待确认
          </div>
          <div class="table-point" v-else-if="text === '30'">
            <span style="background: #52c41a"></span> 已确认
          </div>
          <div class="table-point" v-else-if="text === '93'">
            <span style="background: #f00"></span> 未接受
          </div>
          <div class="table-point" v-else>
            <span style="background: #bfbfbf"></span> 已取消
          </div>
        </span>
      </s-table>
    </div>
    <div class="desc">
      <span
        style="
          display: inline-block;
          width: 2px;
          height: 14px;
          background: #1890ff;
          margin-right: 5px;
        "
      ></span>
      确认开票信息
    </div>
    <div class="desc">
      <a-icon
        theme="filled"
        style="color: #1890ff; margin-right: 4px"
        type="info-circle"
      />请核实发票信息是否正确，如果不正确请联系客户经理进行修改。
    </div>
    <div class="desc" style="font-weight: 500">
      温馨提示：完成结算后，次日即可提交开票申请，我司将在申请提交后的3个工作日内为您开出并邮寄。
    </div>
    <a-alert
      style="margin-bottom: 10px"
      v-if="params.invoiceType === '20' && sjrInfo"
      :message="
        '邮寄地址：' +
        sjrInfo.contactsName +
        '/' +
        sjrInfo.contactsPhone +
        '/' +
        sjrInfo.contactsAddress
      "
      type="success"
    />
    <!-- <a-alert
      style="margin-bottom: 10px"
      v-if="params.invoiceType !== '20' && sjrInfo"
      :message="'邮寄地址：' + userEmail"
      type="success"
    /> -->
    <ul class="content" v-if="baseInfo">
      <li>
        <span class="left">发票抬头：</span>
        <span>{{ baseInfo.name }}</span>
      </li>
      <li>
        <span class="left">税务登记证号：</span>
        <span>{{ baseInfo.creditCode }}</span>
      </li>
      <li>
        <span class="left">开户银行：</span>
        <span>{{ baseInfo.bankName }}</span>
      </li>
      <li>
        <span class="left">银行账号：</span>
        <span>{{ baseInfo.bankNo }}</span>
      </li>
      <li>
        <span class="left">注册场所地址：</span>
        <span>{{ baseInfo.address }}</span>
      </li>
      <li>
        <span class="left">注册电话：</span>
        <span>{{ baseInfo.phone }}</span>
      </li>
      <li>
        <span class="left">开票金额：</span>
        <span>{{ TicketAccount }}元</span>
      </li>
      <li>
        <span class="left">发票类型：</span>
        <a-select v-model="params.invoiceType" style="width: 190px">
          <a-select-option value="10"> 增值税普通发票 </a-select-option>
          <a-select-option value="20"> 增值税专用发票 </a-select-option>
        </a-select>
      </li>
      <li>
        <span class="left">发票样式：</span>
        <span v-if="params.invoiceType === '20'">电子发票</span>
        <span v-else>纸质发票</span>
      </li>
      <li>
        <span class="left">发票内容：</span>
        <a-select
          v-model="params.taskType"
          placeholder="请选择发票内容"
          style="width: 190px"
        >
          <a-select-option
            :value="item.invoiceId"
            v-for="(item, index) in options"
            :key="index"
          >
            {{ item.invoiceName }}
          </a-select-option>
        </a-select>
      </li>
			<li>
			  <span class="left">邮箱：</span>
			  <a-input
			    v-model="params.userEmail"
			    placeholder="请填写接收电子发票有限地址"
					style="width:290px"
			  />
			</li>
      <li style="align-items: flex-start">
        <span class="left">备注：</span>
        <a-textarea
          v-model="params.remark"
          placeholder="请填写开票说明"
          style="width: 350px; height: 84px"
        ></a-textarea>
      </li>
      <!-- <li>
        <span class="left">邮寄地址：</span>
        <span>{{sjrInfo.contactsAddress}}</span>
      </li> -->
      <li>
        <span class="left"></span>
        <div>
          <a-button
            type="primary"
            :loading="loading"
            @click="submit"
            :disabled="isDisabled"
            >提交</a-button
          >
          <a-button style="margin-left: 8px" @click="cancel">取消</a-button>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import STable from "@/components/table/";
import {
  ticketInfos,
  getRaskType,
  submitTicket,
  getInvoiceMailed,
  getInvoiceByIndustryId,
  getUserBindEmail,
  taskWorkerPayList,
  confirmInvoice,
  applyInvoiceList,
} from "@/api/api";
export default {
  components: {
    STable,
  },
  data() {
    return {
      ticketInfo: null,
      baseInfo: null,
      options: [],
      params: {
        taskType: undefined,
        remark: "",
        invoiceType: "20",
				userEmail:'',
      },
      sjrInfo: null,
      loading: false,
      columnsQyry: [
        // {
        //   title: '任务名称',
        //   dataIndex: 'title',
        //   customRender: text => text===null?'--':text
        // },
        {
          title: "批次号",
          dataIndex: "orderBatchId",
          customRender: (text) => (text === null ? "--" : text),
        },
        {
          title: "支付笔数",
          dataIndex: "payNum",
          customRender: (text) => (text === null ? "--" : text),
        },
        {
          title: "开票金额",
          dataIndex: "invoiceAmount",
          customRender: (text) => (text === null ? "--" : text.toFixed(2)),
        },
        // {
        //   title: '支付金额',
        //   dataIndex: 'payAmount',
        //   customRender: text => text===null?'--':text.toFixed(2)
        // },
        // {
        //   title: '费用金额',
        //   dataIndex: 'payFeeAmount',
        //   customRender: text => text === null?'--':text.toFixed(2)
        // },
        // {
        //   title: '补缴金额',
        //   dataIndex: 'supplementAmount',
        //   customRender: text => text === null?'--':text.toFixed(2),
        // },
        // {
        //   title: '支付总金额',
        //   dataIndex: 'payTotalAmount',
        //   customRender: text => text === null?'--':text.toFixed(2)
        // },
        // {
        //   title: '付款周期开始时间',
        //   dataIndex: 'payPeriodStartTime',
        //   customRender: text => text===null?'--':text
        // },
        // {
        //   title: '付款周期结束时间',
        //   dataIndex: 'payPeriodEndTime',
        //   customRender: text => text===null?'--':text
        // },
        {
          title: "付款时间",
          dataIndex: "payTime",
          customRender: (text) => (text === null ? "--" : text),
        },
      ],
      selectedRowKeys: [],
      currentSelectedKeys: [],
      stashCurrentSelectedKeys: [], // 缓存所有的 currentSelectedKeys
      isDisabled: true,
    };
  },
  created() {
    this.getInvoiceMailed();
    this.ticketInfo = JSON.parse(window.localStorage.getItem("ticketInfo"));
    this.getContent();
    this.getTicketBaseInfo();
  },
  computed: {
    TicketAccount() {
      if (this.currentSelectedKeys.length === 0) {
        return 0;
      } else {
        let res = 0;
        this.currentSelectedKeys.forEach((item) => {
          // res += item.payTotalAmount
          res += item.invoiceAmount;
        });
        return parseFloat(res).toFixed(2);
      }
    },
  },
  methods: {
    // 表格操作
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log("selectedRowKeys", selectedRowKeys);
      console.log("selectedRows", selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      selectedRows.forEach((item, index) => {
        this.stashCurrentSelectedKeys.push(item);
      });

      let arr = [];
      this.selectedRowKeys.map((item) => {
        for (let i = 0; i < this.stashCurrentSelectedKeys.length; i++) {
          if (item === this.stashCurrentSelectedKeys[i].payId) {
            arr.push(this.stashCurrentSelectedKeys[i]);
            return false;
          }
        }
      });

      this.currentSelectedKeys = arr;

      if (selectedRows.length > 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    // 获取getQyryList
    dataQyry(parameter) {
      return applyInvoiceList(
        Object.assign(
          { taskId: this.ticketInfo.taskId, invoiceStatus: "10" },
          parameter
        )
      ).then((res) => {
        if (res.success) {
          if (res.result.data.length > 0) {
            res.result.data.forEach((item) => {
              if (item.signStatus === "30") {
                this.canCancel = false;
              }
            });
          }
        }
        // console.log(res);
        return res.result;
      });
    },
    // 获取发票内容
    getContent() {
      getInvoiceByIndustryId(this.ticketInfo.taskType).then((res) => {
        this.options = res.result;
      });
    },
    // 查询收件人信息
    getInvoiceMailed() {
      getInvoiceMailed().then((res) => {
        if (res.success) {
          this.sjrInfo = res.result;
        }
      });
    },
    // 获取发票信息
    getTicketBaseInfo() {
      ticketInfos().then((res) => {
        if (res.success) {
          this.baseInfo = res.result;
          // getUserBindEmail().then((res) => {
          //   // console.log(res)
          //   if (res.success) {
          //     this.userEmail = res.message;
          //   }
          // });
        }
      });
    },
    // 提交
    submit() {
      if (this.params.taskType === undefined) {
        this.$message.error("请选择发票内容");
        return;
      }
      if (this.currentSelectedKeys.length === 0) {
        this.$message.error("请选择需要开票的任务");
        return;
      }
			if(this.params.userEmail == ''){
				this.$message.error("请填写电子发票接收邮箱");
				return;
			}
      this.loading = true;
      let params = {};
      let invoiceIds = [];
      this.currentSelectedKeys.forEach((item) => {
        invoiceIds.push(item.orderBatchId);
      });
      invoiceIds = Array.from(new Set(invoiceIds)).join(",");
      if (this.params.invoiceType === "10") {
        params = {
          actualInvoiceAmount: this.TicketAccount,
          applyAmount: this.TicketAccount,
          bankName: this.baseInfo.bankName,
          bankNo: this.baseInfo.bankNo,
          batchId: invoiceIds,
          contactPhone: this.baseInfo.phone,
          contactsAddress: this.sjrInfo.contactsAddress,
          contactsName: this.sjrInfo.contactsName,
          contactsPhone: this.sjrInfo.contactsPhone,
          creditCode: this.baseInfo.creditCode,
          enterpriseId: this.baseInfo.enterpriseId,
          invoiceName: this.baseInfo.name,
          invoiceType: this.params.invoiceType,
          oweTicketAmount: 0,
          registerAddress: this.baseInfo.address,
          remark: this.params.remark,
          scopeId: this.params.taskType,
          taskId: this.ticketInfo.taskId,
          sendEmail: this.params.userEmail,
          payId: this.selectedRowKeys,
        };
      } else {
        params = {
          actualInvoiceAmount: this.TicketAccount,
          applyAmount: this.TicketAccount,
          bankName: this.baseInfo.bankName,
          bankNo: this.baseInfo.bankNo,
          batchId: invoiceIds,
          contactPhone: this.baseInfo.phone,
          contactsAddress: this.sjrInfo.contactsAddress,
          contactsName: this.sjrInfo.contactsName,
          contactsPhone: this.sjrInfo.contactsPhone,
          creditCode: this.baseInfo.creditCode,
          enterpriseId: this.baseInfo.enterpriseId,
          invoiceName: this.baseInfo.name,
          invoiceType: this.params.invoiceType,
          oweTicketAmount: 0,
          registerAddress: this.baseInfo.address,
          remark: this.params.remark,
          scopeId: this.params.taskType,
					sendEmail: this.params.userEmail,
          payId: this.selectedRowKeys,
        };
      }
      confirmInvoice(params).then((res) => {
        this.loading = false;
        if (res.success) {
          this.$message.success(res.message);
          this.cancel();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 取消
    cancel() {
      window.localStorage.setItem("submenu", "kkfp");
      this.$router.push("/ticket");
    },
  },
};
</script>
<style scoped lang="less">
.container {
  font-size: 14px;
  color: #202020;
}
.header {
  font-size: 24px;
  color: #202020;
}
.desc {
  margin: 20px 0;
  display: flex;
  align-items: center;
}
.content {
  padding: 0;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .left {
      width: 100px;
      text-align: right;
    }
    &:last-child {
      align-items: flex-start;
    }
  }
}
</style>